import { createSlice } from '@reduxjs/toolkit';
// utils
import API from '../../utils/apiConfig';
import { get, post } from '../../utils/server';
///
import { dispatch } from '../store';

// ----------------------------------------------------------------------
const actionsNames = {
  close: 'close',
  freeze: 'freeze',
  hide: 'hide',
  open: 'open',
};
const initialState = {
  isLoading: false,
  actionsOn: [],
  error: null,
  accounts: [],
  account: null,
  sortBy: null,
};

const slice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Accounts
    getAccountsSuccess(state, action) {
      state.isLoading = false;
      state.accounts = action.payload;
    },

    // Get Accounts Releated to Business
    getBusinessAccountsSuccess(state, action) {
      state.isLoading = false;
      state.accounts = action.payload;
    },

    // Get Accounts Releated to Customer
    getCustomerAccountsSuccess(state, action) {
      state.isLoading = false;
      state.accounts = action.payload;
    },

    // GET single Account
    getAccountSuccess(state, action) {
      state.isLoading = false;
      state.account = action.payload;
    },

    // Actions ///
    closeAccountSuccess(state, action) {
      state.isLoading = false;
      state.account = { ...state.account, status: 'CLOSED' };
    },
    freezeAccountSuccess(state, action) {
      state.isLoading = false;
      state.account = { ...state.account, status: 'FROZEN' };
    },
    hideAccountSuccess(state, action) {
      state.isLoading = false;
      state.account = { ...state.account, status: 'HIDDEN' };
    },
    openAccountSuccess(state, action) {
      state.isLoading = false;
      state.account = { ...state.account, status: 'OPEN' };
    },

    // Actions started
    actionStarted(state, action) {
      state.actionsOn = [...state.actionsOn, action.payload];
    },
    actionEnd(state, action) {
      state.actionsOn = state.actionsOn.filter((actionType) => actionType !== action.payload);
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

// Need endpoint
export function getAccounts(query) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await get(API.GET_ACCOUNTS(query));
      dispatch(slice.actions.getAccountsSuccess(response.accounts));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBusinessAccounts(businessId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const query = {
        business_id: businessId,
        accounts_owner_type: 'Business',
      };
      const response = await get(API.GET_ACCOUNTS(query));
      dispatch(slice.actions.getBusinessAccountsSuccess(response.accounts));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCustomerAccounts(customerId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const query = {
        customer_id: customerId,
        accounts_owner_type: 'Customer',
      };
      const response = await get(API.GET_ACCOUNTS(query));
      dispatch(slice.actions.getCustomerAccountsSuccess(response.accounts));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getCustomerAccount(accountId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await get(API.GET_ACCOUNT(accountId));
      dispatch(slice.actions.getAccountSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBusinessAccount(accountId, businessId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await get(API.GET_ACCOUNT(accountId));
      dispatch(slice.actions.getAccountSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function closeAccount(accountId, callBack) {
  return async () => {
    dispatch(slice.actions.actionStarted(actionsNames.close));
    try {
      const response = await post({ path: API.CLOSE_ACCOUNT(accountId) });
      callBack('Account Closed Successfuly', 'success');
      dispatch(slice.actions.closeAccountSuccess(response));

      // to Remove loading from button
      dispatch(slice.actions.actionEnd(actionsNames.close));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.actionEnd(actionsNames.close));
    }
  };
}

export function hideAccount(accountId, callBack) {
  return async () => {
    dispatch(slice.actions.actionStarted(actionsNames.hide));
    try {
      const response = await post({ path: API.HIDE_ACCOUNT(accountId) });
      callBack('Account Hidded Successfuly', 'success');
      dispatch(slice.actions.hideAccountSuccess(response));
      dispatch(slice.actions.actionEnd(actionsNames.hide));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.actionEnd(actionsNames.hide));
    }
  };
}

export function freezeAccount(accountId, callBack) {
  return async () => {
    dispatch(slice.actions.actionStarted(actionsNames.freeze));
    try {
      const response = await post({ path: API.FREEZE_ACCOUNT(accountId) });
      callBack('Account Frozen Successfuly', 'success');
      dispatch(slice.actions.freezeAccountSuccess(response));
      dispatch(slice.actions.actionEnd(actionsNames.freeze));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.actionEnd(actionsNames.freeze));
    }
  };
}

export function openAccount(accountId, callBack) {
  return async () => {
    dispatch(slice.actions.actionStarted(actionsNames.open));
    try {
      const response = await post({ path: API.OPEN_ACCOUNT(accountId) });
      callBack('Account Opened Successfuly', 'success');
      dispatch(slice.actions.openAccountSuccess(response));
      dispatch(slice.actions.actionEnd(actionsNames.open));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.actionEnd(actionsNames.open));
    }
  };
}
